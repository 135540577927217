<template>
  <div class="feedback">
    <HeadNav/>
    <div class="tip-box">*向我们发送关于您使用过程遇到的问题</div>
    <yd-cell-group>
      <yd-cell-item>
        <yd-textarea slot="right" placeholder="请填写您遇到的问题，感谢您的支持" v-model="message"></yd-textarea>
      </yd-cell-item>
    </yd-cell-group>
    <div class="save-box" @click="saveFeedback">
      <div class="save-btn">保存</div>
    </div>
  </div>
</template>

<script>
import HeadNav from "@/components/common/HeadNav";
import {feedbackSuggest} from "@/services/my";

export default {
  name: "feedback",
  data() {
    return {
      message: '',//用户反馈
    }
  },
  components: {
    HeadNav
  },
  methods: {
    /**
     * 保存意见反馈
     * @returns {Promise<void>}
     */
    async saveFeedback() {
      if (this.message) {
        try {
          const res = await feedbackSuggest(this.message);
          if (res.code === 0) {
            this.$dialog.toast({
              mes: '保存成功',
              icon: 'success',
              timeout: 1500
            });
            this.$router.go(-1)
          }
        } catch (e) {

        }
      } else {
        this.$dialog.toast({
          mes: '请填写您遇到的问题，感谢您的支持',
          timeout: 1500
        });
      }

    },
  }
}
</script>

<style lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";


.feedback {
  .tip-box {
    .wh(100%, 0.96rem);
    background: var(--main-color);
    line-height: 0.96rem;
    padding: 0 0.24rem;
    .sc(0.32rem, #fff);
  }

  yd-textarea {
    padding: .24rem 0;

  }

  .yd-textarea > textarea {
    height: 3.16rem;
    .sc(0.32rem, #333);
  }

  .yd-textarea > textarea::placeholder {
    .sc(0.32rem, @color-light-grey);
  }

  .save-box {
    .wh(100%, 0.88rem);
    padding: 0 0.24rem;
    margin-top: 0.4rem;
  }

  .save-btn {
    .wh(100%, 100%);
    background: var(--main-color);
    border-radius: 1rem;
    line-height: 0.88rem;
    .sc(0.32rem, #fff);
    text-align: center;
  }
}
</style>