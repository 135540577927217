var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "feedback" },
    [
      _c("HeadNav"),
      _c("div", { staticClass: "tip-box" }, [
        _vm._v("*向我们发送关于您使用过程遇到的问题"),
      ]),
      _c(
        "yd-cell-group",
        [
          _c(
            "yd-cell-item",
            [
              _c("yd-textarea", {
                attrs: {
                  slot: "right",
                  placeholder: "请填写您遇到的问题，感谢您的支持",
                },
                slot: "right",
                model: {
                  value: _vm.message,
                  callback: function ($$v) {
                    _vm.message = $$v
                  },
                  expression: "message",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "save-box", on: { click: _vm.saveFeedback } }, [
        _c("div", { staticClass: "save-btn" }, [_vm._v("保存")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }